import { MantineTheme, parseThemeColor } from '@mantine/core';
import { CSSProperties, forwardRef } from 'react';

import { KeyOf } from '@/common/models/KeyOf';
import { ThemeColors, useTheme } from '@/theme';

import { Box } from '../Display/Box';
import classes from './Spinner.module.css';

export interface SpinnerProps {
  size?: string | number;
  colorVariant?: KeyOf<ThemeColors>;
  color?: string;
  mixBlendDifference?: boolean;
  style?: CSSProperties;
  borderWidth?: number;
}

const resolveColor = (
  theme: MantineTheme,
  colorVariant?: KeyOf<ThemeColors>,
  color?: string
) => {
  if (colorVariant)
    return `var(${parseThemeColor({ color: colorVariant, theme }).variable})`;
  if (color) return color;
  return 'currentColor';
};

export const Spinner = forwardRef<HTMLSpanElement, SpinnerProps>(
  (
    { size, color, colorVariant, mixBlendDifference, style, borderWidth = 2 },
    ref
  ) => {
    const theme = useTheme();
    return (
      <Box
        component="span"
        ref={ref}
        data-mix-blend-difference={mixBlendDifference}
        data-spinner
        style={{
          width: size ?? '1.5rem',
          height: size ?? '1.5rem',
          borderColor: resolveColor(theme, colorVariant, color),
          borderBottomColor: 'transparent',
          borderLeftColor: 'transparent',
          borderWidth: borderWidth,
          ...style
        }}
        className={classes.root}
      />
    );
  }
);
